export type User = {
	id: number | string
	personId: string
	language: string
	email: string
	password: string | null
	login: string
	namespace: string
	familyName: string
	firstName: string
	missedCallReport: boolean
	faxCallerId: string
	faxHeader: string
	faxCoverPage: boolean
	faxEmailJournal: boolean
	licenseType: 'User' | 'UserLight'
	createdAt: string
}

import RestClient from './RestClient'

export default class Users {
	rest
	url = '/rest/users/'

	constructor() {
		this.rest = new RestClient(this.url)
		return this
	}

	get get(): Promise<User[]> {
		return this.rest.response as unknown as Promise<User[]>
	}

	get me(): Promise<User | null> {
		this.rest.url = this.url + 'me'
		return this.rest.response.then((response) => {
			if (response.ok) {
				return response.json()
			}
			return new Promise(() => null)
		}).catch((response) => {
			console.log(response.json())
			return new Promise(() => null)
		})
	}

	getPhoneNumberConfig(accountId: string): Promise<Response> {
		this.rest.url = this.url + accountId + '/phonenumberconfig'
		return this.rest.response.then((response) => {
			if (response.ok) {
				return response.json()
			}
			return Promise.reject()
		}).catch((response) => {
			console.log(response.json())
			return Promise.reject()
		})
	}
}
