/*
 *  Copyright (C) by STARFACE GmbH, Karlsruhe, Germany. All rights reserved.
 *
 *  NOTICE: THIS MATERIAL IS CONSIDERED A TRADE SECRET BY STARFACE GMBH. UNAUTHORIZED ACCESS,
 *  USE, REPRODUCTION OR DISTRIBUTION IS PROHIBITED.
 */

import { merge } from 'lodash'
import { useAppStore } from '@/app/app-store'
import OAuthLogin from '@/services/OAuthLogin'

const PBX_BASE = process.env.NODE_ENV === 'development' ? import.meta.env.VITE_PBX_BASE : `https://${window.location.host}`

export default class RestClient {
	#authenticate = true
	#options = {
		headers: new Headers({
			Accept: 'application/json',
			'Content-Type': 'application/json',
			'X-Version': '2'
		}),
		method: 'GET',
		mode: 'cors'
	}

	#url: string = PBX_BASE + '/rest'

	private appStore

	constructor(url?: string, needsAuth?: boolean, options?: RequestInit) {
		this.appStore = useAppStore()

		if (url) {
			this.#url = PBX_BASE + url
		}
		if (options) {
			this.setOptions(options as RequestInit)
		}
		if (needsAuth !== undefined) {
			this.#authenticate = needsAuth
		}
		if (this.#authenticate) {
			this.setAuth()
		}
	}

	get response(): Promise<Response> {
		(this.#options as RequestInit).body = null
		return this.request(({ method: 'GET' } as RequestInit))
	}

	async request(options?: RequestInit): Promise<Response> {
		if (options) {
			this.setOptions(options as RequestInit)
		}
		if (!this.#authenticate || await this.setAuth()) {
			return fetch(this.#url, this.#options as RequestInit)
				.then(response => {
					if (response.status === 401) {
						new OAuthLogin().status401OnRequestOccured()
						return Promise.reject({
							code: '4ea583d2-746a-4d9f-8df2-4c099fb9ca63',
							message: 'invalid token, bro!'
						})
					} else {
						return response
					}
				})
		}
		return Promise.reject({
			code: 'b68412a8-6a6e-4bb5-8afc-0503f620ff4e',
			message: 'missing token, bro!'
		})
	}

	setAuth = async (token?: string): Promise<boolean> => {
		if (token) {
			this.appStore.setToken(token)
		} else {
			token = this.appStore.authToken
		}

		const oOAuth = new OAuthLogin()

		if (token) {
			this.#options.headers.set('authToken', token)
			return true
		} else if (await oOAuth.ensureActiveAccessToken()) {
			this.#options.headers.set('Authorization', `Bearer ${oOAuth.accessToken}`)
			return true
		}
		return false
	}

	setOptions = (options: RequestInit) => {
		(this.#options as RequestInit).body = null
		if (options.body) {
			options.body = JSON.stringify(options.body)
		}
		merge(this.#options, options)
	}

	delete(options?: RequestInit) {
		this.#options.method = 'DELETE'
		return this.request(options)
	}

	post(options?: RequestInit) {
		this.#options.method = 'POST'
		return this.request(options)
	}

	put(options?: RequestInit) {
		this.#options.method = 'PUT'
		return this.request(options)
	}

	set url(url: string) {
		this.#url = PBX_BASE + url
	}
}
